export const PRESET_ENVELOPES = {
  // Spending Categories
  Groceries: {
    type: 1,
    fullAmount: 250,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 1, // Weekly
  },
  "Rent/Mortgage": {
    type: 1,
    fullAmount: 1500,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
  },
  Utilities: {
    type: 1,
    fullAmount: 200,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
  },
  Transportation: {
    type: 1,
    fullAmount: 300,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
  },
  Entertainment: {
    type: 1,
    fullAmount: 200,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 1, // Weekly
  },
  "Credit Card": {
    type: 1,
    fullAmount: 400,
    showProgress: false,
    showProgressTick: false,
    timeFrame: 1,
  },
  // Savings Categories
  "Emergency Fund": {
    type: 2,
    fullAmount: 5000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 1, // Not applicable but default to weekly
  },
  Vacation: {
    type: 2,
    fullAmount: 2000,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 4, // Yearly
  },
  "Home Down Payment": {
    type: 2,
    fullAmount: 20000,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 4, // Yearly
  },
};
